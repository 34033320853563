// $blue: #283ba7;
// $off_white: #d4c8b8;
// $black: #050505;
// $gray: #494844;
// $brown: #5c3520;
// $brown: #9c5e3c;
// $gray_brown: #30261d;
// $white: #ffffff;
// $teal: #2f3847;
// $green: #384937;

$main-font: #efebe1;

$mainbackground: #c37e61;
$input-box-shadow: #fff6da;
$select-box-shadow: #fff6da;
$button-background: #ad8a4b;
$button-background-hover: #2f3847;
$button-border: #ad8a4b;
$button-border-focus: #fff6da;
$button-border-hover: #fff6da;

$form-success-color: #4f63ff;
$form-success-border: #4f63ff;
$form-error-color: #ff5252;
$form-error-border: #ff5252;

$service-selector-background: #855d49;
$service-selector-background-hover: #e0ded465;
$service-selector-background-active: #ad8a4b;
$service-selector-background-disabled: #a3a3a357;
$service-selector-border: #ad775c;

$main-form-background: #a06242f1;
$main-form-border: #a0624249;

$utility-container-background: #a06242f1;
$utility-container-border: #ffffff49;
$utility-button-color: #412e16;
$utility-button-color-hover: #f5f3f0;
$utility-button-background: #fff6da;
$utility-button-background-focus: #a06242;
$utility-button-background-hover: #a06242;
$utility-button-border-color: #fff6da;
$utility-button-border-color-focus: #a06242;
$utility-button-border-color-hover: #b38670;

$utility-checkbox-color: #a06242;
$utility-checkbox-background-before: #524e4993;
$utility-checkbox-border-before: #a06242;
$utility-checkbox-border-checked: #a06242;
$utility-checkbox-border-disabled: #03132b;
